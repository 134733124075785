import { Fragment, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHandleError } from '../components/utils/useHandleError';
import Head from 'next/head';
import { useRouter } from "next/router";
import {
    APP_TITLE,
    APP_PAGE_DESCRIPTION_EN,
    APP_PAGE_DESCRIPTION_FR
} from "../variables";

const LoaderWindow = ({ loadingProgress, invisible }) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const lang = router.query.lang;
    const pageDescription = lang === 'fr' ? APP_PAGE_DESCRIPTION_FR : APP_PAGE_DESCRIPTION_EN;
    const favicon = useSelector(state => state.cms.content.favicon);

    useEffect(() => {
        let timer = setTimeout(() => useHandleError(dispatch, 408), 30000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Fragment>
            <Head>
                <meta name="theme-color" content="#37235a" />
                <title>{APP_TITLE}</title>
                <meta name="description" content={pageDescription}/>
                <meta property="og:image" content={favicon}/>
                <meta property="og:title" content={APP_TITLE}/>
                <meta name="description" content={pageDescription}/>
                <meta property="og:description" content={pageDescription}/>
            </Head>
            <div className='loader-wrapper' style={{opacity: invisible ? 0 : 1}}>
                <div className='loader'>
                    <img src={`/images/logo.png`} alt="Reels of luck loader"/>
                    <div className="loading-wrapper">
                        <div className="progress-bar">
                            <span style={{width: `${loadingProgress}%`}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default LoaderWindow;
